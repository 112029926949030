import get from 'lodash/get';

import { post } from '../../api';
import store from '../store';
import { refreshAuth, setNewsletter, setLocalNewletterConsent } from './auth';
import State from '../../Helpers/State';
import { setOptinSuccess, setOptinError } from './optin-message';

export const EMAIL_INITIAL = 'frontend/email-subscribe/EMAIL_INITIAL';
export const EMAIL_SUBSCRIBE = 'frontend/email-subscribe/EMAIL_SUBSCRIBE';
export const EMAIL_ERROR = 'frontend/email-subscribe/EMAIL_ERROR';

export const initialState = {
  currentState: EMAIL_INITIAL,
  emailResponse: '',
  errorText: '',
  context: '',
};

const startResetCounter = () => {
  const displayTime = 1000 * 5;
  window.setTimeout(() => {
    store.dispatch({ type: EMAIL_INITIAL, payload: '' });
  }, displayTime);
};

export function postEmail(email, context, ignoreDouble, newsletter, country) {
  let ignoreDoubleOpt = false;
  if (ignoreDouble) {
    ignoreDoubleOpt = true;
  }

  const dataToSend = {
    ignoreDoubleOpt,
    email,
    subscribedFrom: context,
    newsletterOption: newsletter,
  };
  if (country) {
    dataToSend.countryPassed = country;
  }

  return async (dispatch) => {
    if (!newsletter) setLocalNewletterConsent(false);
    if (newsletter) {
      localStorage.setItem('singleOptinConsentGiven', Date.now());
    }

    const { response, data } = await post(dispatch, '/crm/subscribe', {
      ignoreDoubleOpt,
      email,
      subscribedFrom: context,
      newsletterOption: newsletter,
    });

    let text;
    let reason;
    const state = store.getState();
    const pageId = State.getPageId();
    if (response.ok) {
      const returnStatusText = get(data, 'message', '');
      text = 'Thank you for subscribing';
      if (returnStatusText !== '') {
        text = returnStatusText;
      }

      // Now that we are replying on mailchimp webhooks the update to auth may happen
      // some time later so refreshing auth here may well make no difference
      if (state.auth && state.auth.authenticated) {
        dispatch(refreshAuth());
      }

      startResetCounter();
      if (!newsletter) {
        return store.dispatch({ type: EMAIL_INITIAL, payload: '' });
      }
      return dispatch({ type: EMAIL_SUBSCRIBE, payload: { text, context, pageId, email } });
    }

    switch (response.status) {
      case 400:
        text = 'Invalid email address. Please try again.';
        reason = 'Invalid email address';
        startResetCounter();
        return dispatch({ type: EMAIL_ERROR, payload: { text, context, pageId, email, reason } });

      case 500:
        text = 'Unable to subscribe. Please try again.';
        reason = 'Unable to subscribe';
        startResetCounter();
        return dispatch({ type: EMAIL_ERROR, payload: { text, context, pageId, email, reason } });

      default:
        text = 'An error occured. Please try again.';
        reason = 'Error occured';
        startResetCounter();
        return dispatch({ type: EMAIL_ERROR, payload: { text, context, pageId, email, reason } });
    }
  };
}

export function optinConfirm(token) {
  return async (dispatch) => {
    const { response } = await post(dispatch, '/crm/front/double-optin', { token, url: window.location.href });
    if (response.ok) {
      setNewsletter(true);
      return setOptinSuccess();
    }
    return setOptinError();
  };
}

export function reset() {
  store.dispatch({ type: EMAIL_INITIAL, payload: '' });
}
