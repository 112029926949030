import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import { onCLS, onINP, onLCP, onTTFB, onFCP } from 'web-vitals';
import * as amplitude from '@amplitude/analytics-browser';

import PersistStore from './components/App/PersistStore';
import store from './redux/store';
import { setServerSideCookie } from './redux/ducks/cookie';
import { getByCode, getAll } from './config/lang';
import './styles/bootstrap.scss';
import './styles/index.scss';
import AppInitializer from './AppInitializer';
import getUrlParameter from './Helpers/Url/GetUrlParameter';
import './Helpers/LocalStorage';
import { RateLimitError } from './api';
import env from './env';

// initialise amplitude for analytics
amplitude.init(env.amplitudeToken);

window.setServerSideCookie = setServerSideCookie;

const isGoogleTranslated = !!document.querySelector('html').className.match('translated');

if (window.location.hostname === 'frontend.fanatical.com') {
  window.location.hostname = 'www.fanatical.com';
  throw new Error('Direct access to \'frontend.fanatical.com\' not allowed');
}

if (window.location.hostname === 'frontend-prelive.fanatical.com') {
  window.location.hostname = 'prelive.fanatical.com';
  throw new Error('Direct access to \'frontend-prelive.fanatical.com\' not allowed');
}

// On google translate proxied links, redirect to relevant page/language
if (window.location.href.includes('translate.googleusercontent.com')) {
  const lang = getByCode(getUrlParameter('tl')) ? getByCode(getUrlParameter('tl')).code : 'en';
  if (getUrlParameter('u')) {
    const path = getUrlParameter('u').replace('https://www.fanatical.com/en/', '');
    window.location.href = `https://www.fanatical.com/${lang}/${path}`;
    throw new Error('Redirecting from google translate to local language');
  }

  window.location.href = `https://www.fanatical.com/${lang}`;
  throw new Error('Redirecting from google translate to english language with no page');
}

if (window.location.href.includes('featured-carousel')) {
  localStorage.removeItem('language');
}

if (localStorage.getItem('language')) {
  let localStorageLanguage = localStorage.getItem('language');
  const languages = getAll() || [];
  if (
    localStorageLanguage &&
    !languages.find(language => language.code === JSON.parse(localStorageLanguage).code)
  ) {
    localStorageLanguage = '{"code":"en","label":"English","nativeLabel":"English","default":true}';
    localStorage.setItem('language', localStorageLanguage);
  }
  const savedLocale = JSON.parse(localStorageLanguage).code;
  const urlLocale = window.locale;
  const pathname = window.location.pathname.replace(`/${urlLocale}`, '');
  if (savedLocale && getByCode(savedLocale) && urlLocale && savedLocale !== urlLocale && !window.location.search.includes('langRedirect') && !window.location.href.includes('featured-carousel')) {
    const referer = document.referrer;
    const qs = `${window.location.search ? `${window.location.search}&` : '?'}langRedirect=${urlLocale}${referer ? `&langRedirectRef=${btoa(referer).replace(/=/g, '')}` : ''}`;

    window.location.href = `${window.location.origin}/${savedLocale}${pathname}${qs}`;
    throw new Error('Redirecting to local language');
  }
}

let googleTranslateFixAdded = false;

if (isGoogleTranslated) {
  document.addEventListener('DOMSubtreeModified', (e) => {
    if (e.target.tagName === 'HTML' && !googleTranslateFixAdded) {
      if (e.target.className.match('translated')) {
        googleTranslateFixAdded = true;
        /* eslint-disable */
        if (typeof Node === 'function' && Node.prototype) {
          const originalRemoveChild = Node.prototype.removeChild;
          Node.prototype.removeChild = function(child) {
            if (child.parentNode !== this) {
              if (console) {
                console.error('Cannot remove a child from a different parent', child, this);
              }
              return child;
            }
            return originalRemoveChild.apply(this, arguments);
          }

          const originalInsertBefore = Node.prototype.insertBefore;
          Node.prototype.insertBefore = function(newNode, referenceNode) {
            if (referenceNode && referenceNode.parentNode !== this) {
              if (console) {
                console.error('Cannot insert before a reference node', referenceNode, this);
              }
              return newNode;
            }
            return originalInsertBefore.apply(this, arguments);
          }
        }
        /* eslint-enable */
      }
    }
  }, true);
}

const sentryConfig = JSON.parse(window.atob(window.sentryConfigString || window.btoa('{}')));

sentryConfig.ignoreErrors = [
  // Network errors such as going offline or being blocked by a proxy
  'Failed to fetch',
  'The request timed out.',
  'Timeout',
  'Preflight response is not successful',
  'The network connection was lost.',
  'The Internet connection appears to be offline.',
  /NetworkError/i,
  /NetworkError when attempting to fetch resource/i,
  // Safari error in moengage service worker
  'bundleIdentifier cannot be empty.',
  // Service worker browser failure
  'SecurityError: Failed to register a ServiceWorker: ' +
  'No URL is associated with the caller\'s document.',
  'SecurityError: Failed to register a ServiceWorker: ' +
  'The script has an unsupported MIME type (\'text/html\').',
  'TypeError: Cannot set property \'install\' of undefined',
  'Cannot set property \'install\' of undefined',
  'UnhandledRejection "CustomEvent"',
  'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
  '"CustomEvent"',
  'TypeError cancelled',
  'cancelled',
  'TypeError: The operation couldn’t be completed. Software caused connection abort',
  'The operation couldn’t be completed. Software caused connection abort',
  /Software caused connection abort/i,
  /Unspecified error/i,
  /\$ is not defined/i,
  /Non-Error promise rejection captured with keys/i,
  /Invariant Violation/i,
  /too much recursion/i,
  /moz-extension/i,
  /chrome is not defined/i,
  /Indexed Database/, // iOS moengage issue
  /A mutation operation was attempted on a database that did not allow mutations/i, // FF moengage issue
  // Firefox extension DOM access error
  /can't access dead object/i,
  /Property description must be an object: undefined/i,
  /Cannot read property 'content' of undefined/i, //
  /e.data.indexOf is not a function/i, // Adyen checkout error
  /Failed to execute 'insertBefore' on 'Node'/, // Google translate error
  /Failed to load/i, // Sentry issue FRONTEND-RV1
  /Network request failed/i, // Sentry FRONTEND-2325
  /Non-Error promise rejection captured with value: false/i, // Sentry FRONTEND-235A
  /AlgoliaSearchJSONPScriptErrorError/i, // Sentry FRONTEND-5R
  /AlgoliaSearchUnparsableJSONError/i, // Sentry FRONTEND-21GX
  /AbortError/i, // Sentry FRONTEND-QMY
  /ResizeObserver loop limit exceeded/i, // Sentry FRONTEND-1028 - https://stackoverflow.com/a/50387233/1868365
  /ResizeObserver loop completed with undelivered notifications/i, // Sentry FRONTEND-E0P - https://stackoverflow.com/a/50387233/1868365
  /Object Not Found Matching Id/, // FRONTEND-4XA3 https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
  /can't redefine non-configurable property "userAgent"/, // Sentry FRONTEND-1NJ - I think this is GTM, can't see anything in our code that could be doing it
  /fanatical.com\/static\/js\/IceScrape/i, // FRONTEND-5EPM Snowplow chunk seems to be being blocked by adblockers
  /ChunkLoadError/i, // FRONTEND-5EPM - Could never get to the bottom of it, possibly caused by flaky network connections
  /Promise\.resolve is not a function/i, // FRONTEND-5842
  /Loading CSS chunk/i, // FRONTEND-5B20
  /Load failed/i, // FRONTEND-5GXY
  /__tcfapi/i, // Audigent issue - FRONTEND-6VJ0 FRONTEND-6VJ4
  /ifameElement\.contentDocument\.addEventListener/i, // FRONTEND-6ZG5
  /429/, // FRONTEND-6TR1
  /530/, // FRONTEND-6TR1
  /createLauncher/, // Rokt FRONTEND-70NC
  /NotFoundError/, // FRONTEND-6ZJ5
  /Failed to execute 'removeChild' on 'Node'/i, // FRONTEND-6ZJ5
  /@webkit-masked-url/, // Safari extensions
  'V', // Google one tap script
  /Blocked a frame with origin "https:\/\/www\.fanatical\.com" from accessing/, // Usually GTM related
  '10450',
  /out of memory/,
  'Non-Error exception captured', // https://github.com/getsentry/sentry-javascript/issues/2546
  'Non-Error promise rejection captured', // https://github.com/getsentry/sentry-javascript/issues/2546
  'Event `Event` (type=error) captured as promise rejection', // FRONTEND-95ZH - Fandom identity engine
  'Object captured as promise rejection with keys: message, name, transporterStackTrace', // https://github.com/getsentry/sentry-javascript/issues/2546
  /pptr:\/\//, // Puppeteer FRONTEND-91X6
  /beacon\.wikia-services\.com/, // Fandom identity engine FRONTEND-9WJG
  'Self rate limit exceeded - ignore', // Separate rate limit error for when it's already thrown once
];

sentryConfig.denyUrls = [
  /userscript/,
  /moz-extension/,
  /AppData/,
  /moengage\.com\//i,
  /playbuzz\.com\//i,
  /newrelic\.com\//i,
  /ad\.gt\//i,
  /aufp\.io\//i,
  /translate\.baiducontent\.com/i,
  /datadome\.co\//i,
  /rokt\.com/i, // FRONTEND-7VN5
  /clarity\.ms/, // FRONTEND-7XMB
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Safari extensions
  /\/\/hidden\//i,
  /@webkit-masked-url/,
  /\/gsi\/client/, // Google one tap library
  /pay\.google\.com/, // Google Pay
  /script\.google\.com/, // Google Pay
  /embed\.tawk\.to/,
  /paypal\.com/,
  /about:blank/, // FRONTEND-7PRF
  /d\.mailer\.fanatical/, // FRONTEND-6ZG5 Cordial v2 tracker
  /pptr:\/\//, // Puppeteer FRONTEND-91X6
  /beacon\.wikia-services\.com/, // Fandom identity engine FRONTEND-9WJG
];

sentryConfig.tags = sentryConfig.tags || {};

sentryConfig.tags.page_locale = window.locale;
sentryConfig.tags.country = window.countryData ? window.countryData['Country-Name'] : undefined;
sentryConfig.tags.currency = window.countryData ? window.countryData['Currency-Name'] : undefined;


sentryConfig.beforeSend = (e, hint) => {
  try {
    const isNonErrorException = e.exception.values[0].value.startsWith('Non-Error exception captured') || hint.originalException.message.startsWith('Non-Error exception captured');

    if (isNonErrorException) {
      // We want to ignore those kind of errors (FRONTEND-6ZFY)
      return null;
    }

    if (hint.originalException && hint.originalException instanceof RateLimitError) {
      if (hint.originalException.ignore) {
        return null;
      }

      e.fingerprint = [`ratelimit-${hint.originalException.path}`];
    }

    const state = store.getState();

    sentryConfig.tags.siteTheme = state.siteTheme;
    sentryConfig.tags.siteBrand = state.siteBrand;

    sentryConfig.tags.gtmVersion = window.gtmVersion || null;

    /**
     * Shouldn't have to do this, but it doesn't send tags for some reason
     */
    sentryConfig.tags.isGoogleTranslated = isGoogleTranslated;
    sentryConfig.tags.isPolyfill = !!window.isPolyfill;

    e.tags = cloneDeep(sentryConfig.tags);
  } catch (ex) {
    return e;
  }
  return e;
};

sentryConfig.integrations = [
  Sentry.extraErrorDataIntegration(),
  Sentry.httpClientIntegration({
    failedRequestTargets: [/www\.fanatical\.com/],
    failedRequestStatusCodes: [[429, 559]],
  }),
  Sentry.contextLinesIntegration(),
];

sentryConfig.sendDefaultPii = true;

Sentry.init(cloneDeep(sentryConfig));

try {
  localStorage.setItem('localStorageTest', 'test');
  if (localStorage.getItem('localStorageTest') !== 'test') {
    throw new Error('Value doesn\'t match');
  }
  localStorage.removeItem('localStorageTest');

  if (window.location.href.includes('reset-basket')) {
    // Delete anonid, for support to send to people with basket issues
    localStorage.removeItem('bsanonymous');
  }
} catch (ex) {
  Sentry.captureException(new Error('Local Storage not available'));
}

if ('ontouchstart' in window || 'ontouch' in window) {
  window.touchEnabled = true;
} else {
  window.touchEnabled = false;
}

// IE11 Polyfill issue https://github.com/Financial-Times/polyfill-library/issues/163#issuecomment-509689271
// Object.prototype[Symbol.toStringTag] = Object.prototype[Symbol.toStringTag];

const $root = document.getElementById('root');

hydrate(
  <PersistStore store={store}>
    <Provider store={store}>
      <AppInitializer />
    </Provider>
  </PersistStore>,
  $root,
);

setTimeout(() => {
  try {
    import(/* webpackChunkName: "IceScrape" */'./snowplow'); // eslint-disable-line no-unused-expressions
  } catch (e) {
    console.error('sp import failed', e); // eslint-disable-line no-console
  }
}, 500);

// Conditionally show the moengage soft popup based on split test.
// Only trigger split test when moengage would show the popup.
// CSS rule hides #moe-push-div by default, then displays with d-block on Control side of split test
window.addEventListener('MOE_OPT_IN', (e) => {
  if (e.detail === 'soft_ask_shown') {
    const wrapper = document.getElementById('moe-push-div');
    if (wrapper) {
      wrapper.style.display = 'block';
    }
  }
});

const logVitals = ({ name, value, delta, entries, id, ...rest }) => {
  if (window.location.hostname !== 'www.fanatical.com') {
    console.info(`Core Web Vitals - ${name}`, { name, value, delta, entries, id, ...rest }) // eslint-disable-line
  } else {
    store.dispatch({ type: 'WEB_VITAL', payload: { name, value, delta, id } });
  }
};

onCLS(logVitals);
onFCP(logVitals);
onTTFB(logVitals);
onINP(logVitals);
onLCP(logVitals);
