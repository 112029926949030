import _get from 'lodash/get';

import env from '../../env';
import { get, post, del, put } from '../../api';
import store, { history } from '../store';
import { toggleWishlistModal, toggleLoginModal } from './modal';
import { setWishlistFromSlugAction } from '../../redux/ducks/login-actions';
import { REFRESH_AUTH } from './auth';


const FETCH_WISHLIST = 'frontend/wishlist/FETCH_WISHLIST';
const FETCH_MORE = 'frontend/wishlist/FETCH_MORE';
const UPDATE_WISHLIST_ITEM = 'frontend/wishlist/UPDATE_WISHLIST_ITEM';
const EMPTY_WISHLIST = 'frontend/wishlist/EMPTY_WISHLIST';
const SYNC_COMPLETE = 'frontend/wishlist/SYNC_COMPLETE';
const GET_WISHLIST_ON_SALE = 'frontend/wishlist/GET_WISHLIST_ON_SALE';
const WISHLIST_INTERACTION = 'frontend/wishlist/WISHLIST_INTERACTION';
const GET_WISHLIST_FRIENDS = 'frontend/wishlist/GET_WISHLIST_FRIENDS';
const WISHLIST_ERROR = 'frontend/wishlist/WISHLIST_ERROR';
const WISHLIST_SUCCESS = 'frontend/wishlist/WISHLIST_SUCCESS';

export function fetchWishlist(_sort, page) {
  return async (dispatch) => {
    let url = '/wishlist/getwishlist?';

    const sort = _sort || localStorage.getItem('wishlistSort');

    if (sort) {
      url += `sort=${sort}&`;
    }

    if (page) {
      url += `page=${page}`;
    }

    const { response, data } = await get(dispatch, url);

    if (response.ok) {
      const dispatchData = {
        count: data.nbHits,
        items: data.items,
        allSlugs: data.allSlugs,
        wishlistLoaded: true,
        page: data.page + 1,
        nbPages: data.nbPages,
      };

      if (page > 1) {
        dispatch({ type: FETCH_MORE, payload: dispatchData });
      } else {
        dispatch({ type: FETCH_WISHLIST, payload: dispatchData });
      }
    }
  };
}

export function emptyWishlist() {
  return async (dispatch) => {
    const url = '/wishlist';

    const { response } = await del(dispatch, url);

    if (response.ok) {
      dispatch({ type: EMPTY_WISHLIST });
    }
  };
}

export function saveWishlistItem(interaction) {
  return async (dispatch) => { // eslint-disable-line consistent-return
    const cap = env.wishlistCap;
    const wishlistItems = _get(store.getState(), 'wishlist.items', []);
    if (wishlistItems.length === Number(cap) && interaction.active) {
      return store.dispatch(toggleWishlistModal());
    }

    if ((wishlistItems.length < cap) || (wishlistItems.length <= cap && !interaction.active)) {
      const { response } = await post(dispatch, '/wishlist/save', interaction);
      if (response.ok) {
        dispatch({ type: WISHLIST_INTERACTION,
          payload: {
            productName: interaction.name,
            add: interaction.active,
            remove: !interaction.active,
            location: window.location.href,
            componentType: interaction.componentType || 'HitCard',
            productId: interaction.productId,
            loggedIn: true,
          },
        });
        return store.dispatch(fetchWishlist());
      }
    }
  };
}

export function clearWishlist() {
  store.dispatch({ type: FETCH_WISHLIST, payload: { wishlistLoaded: false } });
  store.dispatch({ type: UPDATE_WISHLIST_ITEM, payload: { active: false } });
}


export function syncSteamWishlist(token, userId, autosync) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/wishlist/syncsteamwishlist', {
      token, userId, autosync,
    });

    if (response.ok) {
      dispatch({ type: SYNC_COMPLETE, payload: data });
      history.push('/wishlist');
    } else {
      dispatch({ type: SYNC_COMPLETE, payload: { success: false } });
      history.push('/wishlist');
    }

    if (autosync && data.token) {
      window.postMessage({ type: 'steamAutoSyncToken', token: data.token }, '*');
    }
  };
}

export function clearWishlistSyncResults() {
  store.dispatch({ type: SYNC_COMPLETE, payload: {} });
}

export function addToWishlistFromSlug(slug) {
  return async (dispatch, getState) => {
    const state = getState();
    const authenticated = _get(state, 'auth.authenticated', false);

    if (!authenticated) {
      store.dispatch(toggleLoginModal());
      setWishlistFromSlugAction(slug);
    } else {
      const { response, data } = await post(dispatch, '/wishlist/addtowishlistfromslug', {
        slug,
      });

      if (response.ok) {
        dispatch({ type: UPDATE_WISHLIST_ITEM, payload: data.item });
        dispatch(fetchWishlist());
      }
    }
  };
}

export function getWishlistOnSale(hitsPerPage = 16) {
  return async (dispatch) => {
    let url = '/wishlist/getwishlistonsale?';

    if (hitsPerPage) {
      url += `hitsPerPage=${hitsPerPage}&`;
    }

    const { response, data } = await get(dispatch, url);

    if (response.ok) {
      dispatch({ type: GET_WISHLIST_ON_SALE, payload: data });
    }
  };
}

export function setupSharableWishlist(wishlistData) {
  return async (dispatch) => {
    const { response, data } = await put(dispatch, '/user/set-up-sharable-wishlist', wishlistData);

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }

    return dispatch({ type: WISHLIST_ERROR, payload: data });
  };
}

export function changeWishlistDisplayName(wishlistData) {
  return async (dispatch) => {
    const { response, data } = await put(dispatch, '/user/change-wishlist-display-name', wishlistData);

    if (response.ok) {
      dispatch({ type: WISHLIST_SUCCESS, payload: true });
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }

    return dispatch({ type: WISHLIST_ERROR, payload: data });
  };
}

export function changeWishlistVisibility(wishlistData) {
  return async (dispatch) => {
    const { response, data } = await put(dispatch, '/user/change-wishlist-visibility', wishlistData);

    if (response.ok) {
      dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function getWishlistFriends() {
  return async (dispatch) => {
    const { response, data } = await get(dispatch, '/user/get-wishlist-friends');

    if (response.ok) {
      dispatch({ type: GET_WISHLIST_FRIENDS, payload: data });
    }
  };
}

export function addWishlistFriend(wishlistId) {
  return async (dispatch) => {
    const { response, data } = await put(dispatch, '/user/add-wishlist-friend', { wishlistId });

    if (response.ok) {
      dispatch({ type: GET_WISHLIST_FRIENDS, payload: data });
    }
  };
}

export function removeWishlistFriend(wishlistId) {
  return async (dispatch) => {
    const { response, data } = await put(dispatch, '/user/remove-wishlist-friend', { wishlistId });

    if (response.ok) {
      dispatch({ type: GET_WISHLIST_FRIENDS, payload: data });
    }
  };
}

export async function getUserWishlist(sort, page, wishlistId) {
  let url = `/wishlist/get-user-wishlist/${wishlistId}?`;

  if (sort) {
    url += `sort=${sort}&`;
  }

  if (page) {
    url += `page=${page}`;
  }

  let wishlistData = { doesNotExist: true };
  try {
    const { response, data } = await get([], url);
    if (response.ok) {
      wishlistData = data;
      wishlistData.page = data.page + 1;
    }
  } catch (ex) { /* */ }

  return wishlistData;
}

export async function clearWishlistError() {
  store.dispatch({ type: WISHLIST_ERROR, payload: '' });
}

export async function clearWishlistSuccess() {
  store.dispatch({ type: WISHLIST_SUCCESS, payload: null });
}

export async function clearFriendsList() {
  store.dispatch({ type: GET_WISHLIST_FRIENDS, payload: [] });
}
