import * as amplitude from '@amplitude/analytics-browser';
import get from 'lodash/get';

import State from '../Helpers/State';

let trackedUserId = null;
let trackedAnonId = null;

/**
 * Creates the Amplitude target
 */
export default (events) => {
  if (!window) {
    return;
  }

  const app = amplitude;

  if (!app) {
    return;
  }

  events.forEach((event) => {
    const auth = State.getState('auth');
    const anonymous = State.getState('anonymous');

    const userId = get(auth, '_id', null);
    const anonId = get(anonymous, 'id', null);

    if (userId !== trackedUserId && userId) {
      app.setUserId(userId);
      trackedUserId = userId;
    }

    if (anonId !== trackedAnonId && anonId) {
      app.setDeviceId(userId);
      trackedAnonId = anonId;
    }

    switch (event.hitType) {
      case 'logEvent':
        app.track(event.eventType, event.eventProperties);
        break;
      default:
        break;
    }
  });
};
