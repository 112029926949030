import store from '../store';

export const RESET_UBISOFT_ERROR = 'frontend/ubisoft-error/RESET_UBISOFT_ERROR';
export const NEW_STATE_UBISOFT_ERROR = 'frontend/ubisoft-error/NEW_STATE_UBISOFT_ERROR';

export const initialState = {
  status: 0,
  error: '',
};

export function resetUbisoftError() {
  store.dispatch({ type: RESET_UBISOFT_ERROR, payload: null });
}

export function newStateUbisoftError(statePassed) {
  store.dispatch({ type: NEW_STATE_UBISOFT_ERROR, payload: statePassed });
}
